<template>
  <footer class="footBox">
    <div class="extra">
      <div class="content-block">
        <el-row>
          <el-col class="H100" :span="6">
            <div class="extraService">
              <div class="extraTitle margin-b30">服务</div>
              <a class="extraItem cursor " href="/home/service" role="link">金标认证</a>
              <div class="extraItem cursor hoverBox ">
                金标认证咨询
                <div class="hoverContent">
                  <p class="item">
                    <span class="label">邮箱: </span>bd@itgsa.com
                  </p>
                  <p class="item">
                    <span class="label">微信: </span> ysq1031111
                  </p>
                  <p class="item">
                    <img class="codeImg" src="@/assets/img/common/wxCode2.png" alt="微信咨询"/>
                  </p>
                </div>
              </div>
              <a class="extraItem cursor " href="/home/union/openness" role="link" target="_blank">联盟开放能力</a>
            </div>
          </el-col>
          <el-col class="H100" :span="6">
            <div class="extraService">
              <svg-icon svg-name="foot-line" class="footLine"></svg-icon>
              <div class="extraTitle margin-b30 w100">友情链接</div>
              <a target="_blank" href="https://open.oppomobile.com/" class="extraItem cursor w100">OPPO开放平台</a>
              <a target="_blank" href="https://dev.vivo.com.cn/home" class="extraItem cursor w100">vivo开放平台</a>
              <a target="_blank" href="https://dev.mi.com/" class="extraItem cursor w100">小米开放平台</a>
              <a target="_blank" href="https://open.lenovomm.com/developer/home" class="extraItem cursor w100">联想开放平台</a>
            </div>
          </el-col>
          <el-col class="H100" :span="6">
            <div class="extraService">
              <svg-icon svg-name="foot-line" class="footLine"></svg-icon>
              <div class="extraTitle margin-b30 ">联系方式</div>
              <div class="extraItem ">邮箱 service@itgsa.com</div>
              <a class="extraItem cursor " href="https://weibo.com/7488524033/profile?topnav=1&wvr=6" target="_blank">微博</a>
              <div class="extraItem codeHover ">
                微信公众号
                <div class="codeImgBox">
                  <img class="codeImg" src="@/assets/img/common/code.png" alt="微信公众号" />
                </div>
              </div>
              <div class="extraItem codeHover ">
                官方微信号
                <div class="codeImgBox">
                  <img class="codeImg" src="@/assets/img/common/wxCode.png" alt="微信公众号" />
                </div>
              </div>
            </div>
          </el-col>
          <el-col class="H100" :span="6">
            <div class="extraService">
                <svg-icon svg-name="foot-line" class="footLine"></svg-icon>
              <div class="extraTitle margin-b30">标准文档</div>
              <a class="extraItem"
                v-for="(item,index) in documentData"
                :key="index" 
                :href="item.href" target="_blank">
                {{item.name}}
              </a>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </footer>
</template>

<script>
import { documentData } from "@/common/VO/homeVO.js";
export default {
  name: "foot",
  data() {
    return {
      documentData: documentData(),
    };
  },
  methods: {
    gotoService() {
      this.$router.push({
        path: "service",
      });
    },

  },
};
</script>

<style scoped lang="scss">
.footBox {
  position: relative;
  background-color: #121212;    
  padding: 40px 0;
  width: 100%;
  z-index: 2;
  a { text-decoration:none; }
  .extra {
    width: 100%;
    margin: auto;
    color: #d6cea7;
    .extraTitle {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      width: 120px;
      margin: auto;
    }
    .extraService {
      position: relative;
      margin-top: 29px;
      margin-bottom: 40px;
      text-align: center;
      .footLine {
        position: absolute;
        top: 0px;
        left: -5px;
        width: 5px;
        height: 100%;
        min-height: 160px;
      }
      .extraItem {
        color: #d6cea7;
        display: block;
        font-size: 14px;
        width:120px;
        margin: 10px auto 10px auto;
        text-align: left;
      }
      .cursor {
        &:hover {
          color: white;
          text-decoration: underline;
        }
      }
      .hoverBox{
        position: relative;
        .hoverContent {
          display: none;
          position: absolute;
          top: -30px;
          left: 110px;
          z-index: 3;
          padding: 10px 14px 15px 14px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 1px 0px rgba(222, 209, 146, 0.5);
          .item{
            display: flex;    
            align-items: center;
            line-height: 24px;
            color: #121212;
            .label{
              min-width: 40px;
            }
            .codeImg{
              width: 100px;
              margin:  auto;
              margin-top: 10px;
            }
          }
          &::after{
            position: absolute;
            top: 30px;
            left: -20px;
            content: '';
            width: 0;
            height: 0;
            border: 10px solid #fff;
            border-color:transparent #fff  transparent transparent ;
          }
        }
        &:hover {
          color: #ffff;
          text-decoration: underline;
          .hoverContent {
            display: block;
          }
        }
      }
      .codeHover {
        margin: 10px auto 0px auto;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        .codeImgBox {
          position: absolute;
          display: none;
          top: -10px;
          right: -70%;
          z-index: 2;
          width: 116px;
          height: 108px;
          background: url("~@/assets/img/common/codeImg.png") top no-repeat;
          .codeImg {
            position: absolute;
            left: 53%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 100px;
            height: 100px;
          }
        }
        &:hover {
          color: #ffff;
          text-decoration: underline;
          .codeImgBox {
            display: block;
          }
        }
      }
      .w200{
        width: 200px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .extra {
      width: 100%;
      /* 兼容移动端 底部栏title宽度*/
      .extraTitle{
        width: 100% !important;
        text-align: center !important;
      }
      /* 兼容移动端 底部栏内容宽度*/
      .extraItem,.codeHover{
        width: 100% !important;
        text-align: center !important;
        font-size: 13px;
        word-wrap:break-word;
      }
    }
  }
}
</style>
