<template>
  <article class="content" id="content">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta || !$route.meta.keepAlive"></router-view>
    <commen-foot id="foot"></commen-foot>
    <ICP></ICP>
  </article>
</template>
<script>
import commenFoot from "@/components/Foot";
import ICP from "@/components/ICP";
import {singleAccountSever} from "@/common/api-config";
import store from '../store'
export default {
  components: {
    commenFoot,ICP
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  margin-top: 80px;
  min-height: calc(100% - 355px); //减头355px(高度+底部)
  background-color: #f8f6f7;
}

@media screen and (max-width: 767px) {
  .content {
    margin-top: 120px;
  }
}
</style>

